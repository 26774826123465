<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                <label for="invoiceCode">Số quyết toán</label>
                                <md-input name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày tạo</span>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="amount">Tổng quyết toán</label>
                                    <md-input name="amount" :value="formatNumber(entity.amount)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>  
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                               <label for="reason">Nội dung</label>
                               <md-input name="reason" v-model="entity.note"></md-input>
                           </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="amount">Tổng dự toán</label>
                                    <md-input name="amount" :value="formatNumber(estimateAmount)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-estimate" class="tab-content" md-label="Tạm ứng">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:120px;">#</th>
                                                            <th style="width:25%;">Số tạm ứng</th> 
                                                            <th style="width:20%;">Người tạm ứng</th> 
                                                            <th style="width:20%;">Ngày tạm ứng</th> 
                                                            <th style="width:20%;">Tổng tạm ứng</th>
                                                            <th style="width:10%">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.advancePaymentList" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.advancePayment.advancePaymentCode" class="form-control" type="text">
                                                                    <md-button @click="openAdvancePaymentList(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm số tạm ứng</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    {{item.advancePayment.staff.fullName}}
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    {{item.advancePayment.advancePaymentDate}}
                                                                </div>
                                                            </td> 
                                                            <td>
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(item.amount)}} VNĐ</div>
                                                            </td>
                                                            <td class="grid-action">
                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="list" style="overflow-x: auto; width: 100%;">
                                                    <table class="data-table-2" style="width: 2300px;"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th rowspan="2" style="width:50px;">#</th> 
                                                                <th rowspan="2" style="width:250px;">Dự toán</th> 
                                                                <th rowspan="2" style="width:250px;">Tên phí</th> 
                                                                <th rowspan="2" style="width:100px;">Loại</th>
                                                                <th rowspan="2" style="width:100px;">Số lượng</th>
                                                                <th colspan="3" style="width:320px; background-color: #96C9F4;">Dự toán</th>
                                                                <th rowspan="2" style="width:120px; background-color: #FFE9D0;">Tạm ứng</th>
                                                                <th colspan="6" style="width:620px; background-color: #ADD899;">Giải chi</th>
                                                                <th colspan="4" style="width:500px; background-color: #F5DAD2;">Quyết toán</th>
                                                            </tr>
                                                            <tr> 
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Đơn giá</th>
                                                                <th class="right" style="width:80px; background-color: #96C9F4;">VAT</th>
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Thành tiền</th>
                                                                <th class="right" style="width:120px; background-color: #ADD899;">Đơn giá</th>
                                                                <th class="right" style="width:80px; background-color: #ADD899;">VAT</th>
                                                                <th class="right" style="width:120px; background-color: #ADD899;">Thành tiền</th>
                                                                <th class="left" style="width:100px; background-color: #ADD899;">Số HĐ</th>
                                                                <th style="width:100px; background-color: #ADD899;">Ngày HĐ</th>
                                                                <th style="width:100px; background-color: #ADD899;">Ngày giải chi</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Đơn giá</th>
                                                                <th style="width:80px; background-color: #F5DAD2;">VAT</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Thành tiền</th>
                                                                <th style="width:180px; background-color: #F5DAD2;">Ghi chú</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <span>{{ item.estimate.estimateCode }}</span>
                                                                </td>
                                                                <td class="left">
                                                                    <span>{{ item.fee.feeName}}</span>
                                                                </td>
                                                                <td class="center">
                                                                    {{ getFeeType(item.type) }}
                                                                </td>
                                                                <td class="center">
                                                                    {{item.quantity}}
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimatePrice)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimateVat)}}</div> 
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.estimateAmount)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.advancePaymentAmount)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainPrice)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainVat)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainAmount)}}</div> 
                                                                </td>
                                                                <td class="left">
                                                                    <div>{{item.billNumber}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{item.billDate}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{item.explainDate}}</div> 
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billPrice" :class="{'is-error': $v.entity.details.$each[index].billPrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number v-model="item.billAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.invoiceNote" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:15%;">Bộ phận/phòng</th> 
                                                            <th style="width:20%;">Người phê duyệt</th> 
                                                            <th style="width:20%;">Tiêu đề</th>
                                                            <th style="width:20%;">Ghi chú</th> 
                                                            <th style="width:15%;">Thứ tự</th> 
                                                            <th style="width:150px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.dept.name" class="form-control" type="text">
                                                                    <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                    <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.title" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.note" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeApprove"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <advancePaymentList ref="advancePaymentList" @close="closeAdvancePayment"/>
   </div>
</template>
<script>
    import projectInvoiceService from '@/api/projectInvoiceService';
    import projectAdvancePaymentService from '@/api/projectAdvancePaymentService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import advancePaymentList from '@/components/popup/_ProjectAdvancePaymentList.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            advancePaymentList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật quyết toán chi phí dự án STST'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, orderType: 1, status: 0, details: [], amount: 0, advancePaymentList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, invoiceCode: '', invoiceDate: common.dateNow, pay: 0, payFee: 0, payOnBehalf: 0, payService: 0 },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                advancePayment: { id: 'id_' + common.getFakeId(), amount: 0, advancePaymentId: 0, estimateId: 0, orderId: 0, order: {orderCode: ''}, advancePayment: { advancePaymentCode: '', advancePaymentDate: '', staff: { fullName: '' }}},
                vat: common.vatF,
                currencyF: common.currencyF2,
                staffName: '',
                staffs: [],
                types: common.typeFee,
                catFee: 0,
                estimateAmount: 0,
            }
        },
        created(){
            this.orderType = this.$route.query.orderType == undefined ? 1 :  this.$route.query.orderType;
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật quyết toán chi phí dự án STST';
               this.getById();
            }
            else{
               this.title = 'Thêm mới quyết toán chi phí dự án STST';
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.advancePaymentList.push(this.advancePayment);
               this.getApproveSetting();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSummary(){
                this.entity.amount = 0;
                this.estimateAmount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.entity.amount = this.entity.amount + parseFloat(this.entity.details[i].billAmount);
                    this.estimateAmount = this.estimateAmount + parseFloat(this.entity.details[i].estimateAmount);
                }
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let price = parseFloat(this.entity.details[selected].billPrice);
                let vat =  parseFloat(this.entity.details[selected].billVat) / 100;
                this.entity.details[selected].billAmount = Math.round(price +  (price * vat)) * parseFloat(this.entity.details[selected].quantity);

                this.calSummary();
            },

            getCode(staffId){
                this.setLoading(true);
                projectInvoiceService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.invoiceCode = response.data.code;
                        this.entity.invoiceNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            addRow(){
                const item = { 
                    id: 'id_' + common.getFakeId(), 
                    advancePayment: { advancePaymentCode: '', advancePaymentDate: '', staff: { fullName: '' } },
                    pay: 0,
                    payFee: 0,
                    payOnBehalf: 0
                };
                this.entity.advancePaymentList.push(item);
            },

            //Estimate Request
            delRow(item){
                const index = this.entity.advancePaymentList.findIndex(x => x.id == item.id);
                this.entity.advancePaymentList.splice(index, 1);
                for(var i = 0; i < this.entity.details.length; i ++){
                    let advancePaymentId = this.entity.details[i].advancePaymentId;
                    
                    if(item.advancePaymentId === advancePaymentId){
                        this.entity.details = this.entity.details.filter(obj => !(obj.advancePaymentId == item.advancePaymentId));
                    }
                }

                this.calSummary();
            },

            closeAdvancePayment(item){
                console.log('item', item)
                const selected = this.entity.advancePaymentList.findIndex(x => x.id == this.selectedId);
                this.entity.advancePaymentList[selected].advancePayment = item;
                this.entity.advancePaymentList[selected].advancePaymentId = item.id;
                this.entity.advancePaymentList[selected].amount = item.amount;
                this.selectedId = '';
                this.getAdvancePaymentById(item.id);
            },

            getAdvancePaymentById(id){
                this.setLoading(true);
                projectAdvancePaymentService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            detail.billPrice = detail.explainPrice;
                            detail.billVat = detail.explainVat;
                            detail.billAmount = detail.explainAmount;
                            let obj = Object.assign({}, detail, { advancePaymentId: id });
                            this.entity.details.push(obj);
                        }
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.advancePaymentList.close();});
            },

            openAdvancePaymentList(id){
                this.selectedId = id;
                this.$refs.advancePaymentList.open();
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.customsAdvancePayment).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairAdvancePayment), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.advancePaymentList.length > 0){
                    this.entity.advancePaymentList = this.entity.advancePaymentList.filter(item => !(item.advancePaymentId == 0));
                    this.entity.advancePaymentList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.advancePaymentList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập tạm ứng chi phí dự án STST");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết quyết toán");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                projectInvoiceService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/project-invoice');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                projectInvoiceService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/project-invoice');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                projectInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/project-invoice?orderType=' + this.orderType);
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                invoiceCode: { required },
                invoiceDate: { required },
                details: {
                    $each: {
                        billPrice: {
                            required,
                            isSelected(value) {
                                if (value >= 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>
